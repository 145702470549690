
body {
  height: 100% !important;
  background-color: #FDFDFD;
}

html {
  height: 100%;
}

.btn-link {
  text-decoration: none !important;
}

/*GENERAL*/
.c-pointer {
  cursor: pointer;
}

/*LAYOUT*/
.main-content {
  min-height: calc(100vh - 83px);
  margin-top: 83px;
  margin-left: 235px;
}

/*HEADER*/
.user-info {
  min-width: 300px;
  top: 65px;
  right: 10px;
  z-index: 10000;
}

.logo-header {
  left: -22px;
}

.avatar {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.avatar-md {
  border-radius: 50px;
  width: 70px;
  height: 70px;
  padding: 5px;
  font-size: 2rem;
}

.user-dropdown i {
  font-size: 1.5rem;
}

/*MENU*/
.menu-title {
  font-size: 0.9rem;
  color: #000000;
  //font-weight: bold;
  user-select: none;
}

.menu-item.active {
  color: $danger;
  font-weight: bold;
}

.menu-item:hover {
}

.menu-icon {
  font-size: 18px;
  font-weight: bold;
}

//quick preview
/* panel layout mechanics */
.panel-wrap {
  position: fixed;
  top: 0px;
  bottom: 0;
  z-index: 1050;
  right: 0;
  background: #FFFFFF;
  width: 60em;
  transform: translateX(100%);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  transition: .3s ease-out;
}

.panel-wrap.show-overlay {
  width: 100% !important;
}

.overlay::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}


.panel-wrap.xl {
  width: 55%;
}

.panel-wrap.sm {
  width: 400px;
}

@media (max-width: 1785px) {
  .panel-wrap.xl {
    width: 75%;
  }
}

.panel-wrap.md {
  width: 50%;
}

.panel-wrap.sm {
  width: 30%;
}

.panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #eee;
  overflow: auto;
  padding: 1em;
}

.preview {
  font-size: 0.8rem;
}

.quick-preview-container {
  height: 100vh;
}

.panel-wrap {
  overflow: auto !important;
}

/*TEXT*/
.line-through {
  text-decoration: line-through;
}

/*BUTTON*/
.btn-rounded {
  width: 40px !important;
  height: 40px !important;
  padding: 6px 0px !important;
  border-radius: 8px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}


/*ACORDION*/
.accordion div.card:only-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px);
}

.accordion .card {
  overflow: visible !important;
}

.fileContainer {
  overflow: hidden;
  position: relative;
}

.fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

/* Example stylistic flourishes */

.fileContainer {
  float: left;
}

.fileContainer [type=file] {
  cursor: pointer;
}

.product-col-width {
  max-width: 400px;
}

.ell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bg-custom {
  background-color: #e3d9f4;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1.125rem;
  font-weight: 500;
  line-height: 1.1
}

.h1, h1 {
  font-size: 1.625rem
}

.h2, h2 {
  font-size: 1.25rem
}

.h3, h3 {
  font-size: 1.0625rem
}

.h4, h4 {
  font-size: .9375rem
}

.h5, h5 {
  font-size: .8125rem
}

.h6, h6 {
  font-size: .625rem
}


//WIZARD
.step-activo {
  border-color: $orange-lunia;
}

.label-xsmall {
  vertical-align: super;
  font-size: x-small;
}

.label-small {
  vertical-align: super;
  font-size: small;
}

.label-medium {
  vertical-align: super;
  font-weight: 550;
  font-size: 0.9rem;
}

.label-large {
  vertical-align: super;
  font-size: large;
}

.label-bs {
  vertical-align: super;
}

/*MONTH PICKER*/
.ngb-dp-header {
  width: 210px !important;
  border-radius: .25rem .25rem .25rem .25rem !important;
  padding-bottom: .25rem !important;
}

.social-icon {
  width: 1.5rem;
}

.social-icon-sm {
  width: 1.2rem;
}

.custom-icon {
  width: 1.2rem;
}

/*Responsive*/
.responsive-card {
  min-height: 100vh;
}

@media (min-width: 576px) {
  .responsive-card {
    min-height: auto;
  }
}


/*CONFIRM*/
.little-confirm {
  width: 10rem;
}

.little-confirm .popover-body {
  padding: 0px;

}

.card {
  margin-bottom: 1.5rem;
  box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03)
}

.card > * {
  flex-shrink: 0
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.card-header-sm {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.card-header > :first-child {
  flex: 1
}

.card-header + .card-footer {
  border-top-width: 0
}

.card-title {
  margin-bottom: .5rem
}

.card-header-title {
  margin-bottom: 0
}

.card-header-tabs {
  margin: -.5rem 0;
}

.card-header-tabs .nav-link {
  padding-top: calc((60px - 1em * 1.5) / 2) !important;
  padding-bottom: calc((60px - 1em * 1.5) / 2) !important
}

.card-table {
  margin-bottom: 0
}

.card-table thead th {
  border-top-width: 0
}

.card-table tbody td:first-child, .card-table thead th:first-child {
  padding-left: 1.5rem !important
}

.card-table tbody td:last-child, .card-table thead th:last-child {
  padding-right: 1.5rem !important
}

.card > .card-table:first-child > tbody:first-child > tr:first-child > td:first-child, .card > .card-table:first-child > tbody:first-child > tr:first-child > th:first-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > td:first-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > th:first-child, .card > .card-table:first-child > thead:first-child > tr:first-child > td:first-child, .card > .card-table:first-child > thead:first-child > tr:first-child > th:first-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > td:first-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > th:first-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > td:first-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > th:first-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > td:first-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > th:first-child {
  border-top-left-radius: .5rem
}

.card > .card-table:first-child > tbody:first-child > tr:first-child > td:last-child, .card > .card-table:first-child > tbody:first-child > tr:first-child > th:last-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > td:last-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > th:last-child, .card > .card-table:first-child > thead:first-child > tr:first-child > td:last-child, .card > .card-table:first-child > thead:first-child > tr:first-child > th:last-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > td:last-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > th:last-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > td:last-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > th:last-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > td:last-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > th:last-child {
  border-top-right-radius: .5rem
}

.card-avatar {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem
}

.card-avatar-top {
  margin-top: -3rem
}

.card-avatar-top.avatar-xs {
  margin-top: -2.3125rem
}

.card-avatar-top.avatar-sm {
  margin-top: -2.75rem
}

.card-avatar-top.avatar-lg {
  margin-top: -3.5rem
}

.card-avatar-top.avatar-xl {
  margin-top: -4.0625rem
}

.card-avatar-top.avatar-xxl {
  margin-top: -5.5rem
}

.card-dropdown {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem
}

.card-inactive {
  border-color: #e3ebf6;
  border-style: dashed;
  background-color: transparent;
  box-shadow: none
}

.card-flush {
  background: 0 0;
  border: none;
  box-shadow: none
}

.card-sm .card-body, .card-sm .card-footer-boxed {
  padding: 1rem
}

.card-header-flush {
  border-bottom: 0
}

.card-header-flush + .card-body {
  padding-top: 0
}

.card-fill {
  height: calc(100% - 1.5rem)
}

.card-fill .card-body {
  flex-grow: 0;
  margin-top: auto;
  margin-bottom: auto
}

.card-fill-xs {
  height: calc(100% - 1.5rem)
}

.card-fill-xs .card-body {
  flex-grow: 0;
  margin-top: auto;
  margin-bottom: auto
}

@media (min-width: 576px) {
  .card-fill-sm {
    height: calc(100% - 1.5rem)
  }

  .card-fill-sm .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 768px) {
  .card-fill-md {
    height: calc(100% - 1.5rem)
  }

  .card-fill-md .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 992px) {
  .card-fill-lg {
    height: calc(100% - 1.5rem)
  }

  .card-fill-lg .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 1200px) {
  .card-fill-xl {
    height: calc(100% - 1.5rem)
  }

  .card-fill-xl .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

.card-adjust {
  height: 0;
  min-height: 100%
}

.card-adjust .card {
  height: calc(100% - 1.5rem)
}

.card-adjust .card-body {
  height: calc(100% - 60px);
  overflow-y: auto
}

.card-adjust-xs {
  height: 0;
  min-height: 100%
}

.card-adjust-xs .card {
  height: calc(100% - 1.5rem)
}

.card-adjust-xs .card-body {
  height: calc(100% - 60px);
  overflow-y: auto
}

@media (min-width: 576px) {
  .card-adjust-sm {
    height: 0;
    min-height: 100%
  }

  .card-adjust-sm .card {
    height: calc(100% - 1.5rem)
  }

  .card-adjust-sm .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 768px) {
  .card-adjust-md {
    height: 0;
    min-height: 100%
  }

  .card-adjust-md .card {
    height: calc(100% - 1.5rem)
  }

  .card-adjust-md .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 992px) {
  .card-adjust-lg {
    height: 0;
    min-height: 100%
  }

  .card-adjust-lg .card {
    height: calc(100% - 1.5rem)
  }

  .card-adjust-lg .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 1200px) {
  .card-adjust-xl {
    height: 0;
    min-height: 100%
  }

  .card-adjust-xl .card {
    height: calc(100% - 1.5rem)
  }

  .card-adjust-xl .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

.card-footer-boxed {
  padding-right: 0;
  padding-bottom: 1.5rem;
  padding-left: 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.card-pagination.pagination-tabs {
  margin-top: -1rem;
  margin-bottom: -1rem;
  border: 0
}

.card-pagination.pagination-tabs .page, .card-pagination.pagination-tabs .page-link {
  border-top-width: 0 !important
}

.card-pagination.pagination-tabs .active .page, .card-pagination.pagination-tabs .active .page-link {
  border-width: 0 0 1px 0 !important
}

.card-divider {
  border-color: #edf2f9
}


.btn-white {
  color: #283e59;
  background-color: #fff;
  border-color: #e3ebf6
}

.btn-white:hover {
  color: #283e59;
  background-color: #ececec;
  border-color: #e6e6e6
}

.btn-white.focus, .btn-white:focus {
  color: #283e59;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.btn-white.disabled, .btn-white:disabled {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.btn-white:not(:disabled):not(.disabled).active, .btn-white:not(:disabled):not(.disabled):active, .show > .btn-white.dropdown-toggle {
  color: #283e59;
  background-color: #e6e6e6;
  border-color: #dfdfdf
}

.btn-white:not(:disabled):not(.disabled).active:focus, .btn-white:not(:disabled):not(.disabled):active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.avatar {
  position: relative;
  display: inline-block
}

.avatar .avatar-name {
  background-color: #344050;
  position: absolute;
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  display: block
}

.avatar-widget {
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-size: 16px !important;
  border: 1.5px solid black;
  font-weight: 600;
}

.avatar .avatar-name > span {
  position: absolute;
  top: 54%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0)
}

.avatar .avatar-emoji {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.avatar .avatar-emoji > span {
  -webkit-transform: translateY(2%);
  -ms-transform: translateY(2%);
  transform: translateY(2%);
  display: inline-block
}

.avatar img {
  display: block
}

.avatar .avatar-name, .avatar img {
  width: 100%;
  height: 100%
}

.avatar img {
  -o-object-fit: cover;
  object-fit: cover
}


.avatar.status-online:before {
  background-color: #00d27a
}

.avatar.status-offline:before {
  background-color: #748194
}

.avatar.status-away:before {
  background-color: #f5803e
}

.avatar.status-do-not-disturb:before {
  background-color: #e63757
}

.avatar .avatar-button {
  background-color: #b6c1d2 !important;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  border: 1px solid #b6c1d2;
  font-size: 12px
}

.avatar .avatar-button:focus, .avatar .avatar-button:hover {
  background-color: #9da9bb !important
}

.avatar:hover .overlay-icon {
  opacity: 1
}

.avatar-s {
  height: 1.25rem;
  width: 1.25rem
}

.avatar-s .avatar-name {
  font-size: .41667rem
}

.avatar-s .avatar-emoji {
  font-size: 1.25rem
}


.avatar-s .rounded-soft {
  border-radius: .20833rem !important
}

.avatar-m {
  height: 1.5rem;
  width: 1.5rem
}

.avatar-m .avatar-name {
  font-size: .5rem
}

.avatar-m .avatar-emoji {
  font-size: 1.5rem
}


.avatar-m .rounded-soft {
  border-radius: .25rem !important
}

.avatar-l {
  height: 1.75rem;
  width: 1.75rem
}

.avatar-l .avatar-name {
  font-size: .58333rem
}

.avatar-l .avatar-emoji {
  font-size: 1.75rem
}


.avatar-l .rounded-soft {
  border-radius: .29167rem !important
}

.avatar-xl {
  height: 2.9rem;
  width: 2.9rem
}

.avatar-xl .avatar-name {
  font-size: .66667rem
}

.avatar-xl .avatar-emoji {
  font-size: 2rem
}

.avatar-sm {
  height: 1.5rem;
  width: 1.5rem
}

.avatar-sm .rounded-soft {
  border-radius: .33333rem !important
}

.avatar-sm .avatar-name {
  font-size: .66667rem
}

.avatar-sm .avatar-emoji {
  font-size: 2rem
}


.avatar-xl .rounded-soft {
  border-radius: .33333rem !important
}

.avatar-2xl {
  height: 2.5rem;
  width: 2.5rem
}

.avatar-2xl .avatar-name {
  font-size: .83333rem
}

.avatar-2xl .avatar-emoji {
  font-size: 2.5rem
}


.avatar-2xl .rounded-soft {
  border-radius: .41667rem !important
}

.avatar-3xl {
  height: 3.5rem;
  width: 3.5rem
}

.avatar-3xl .avatar-name {
  font-size: 1.16667rem
}

.avatar-3xl .avatar-emoji {
  font-size: 3.5rem
}


.avatar-3xl .rounded-soft {
  border-radius: .58333rem !important
}

.avatar-4xl {
  height: 80px;
  width: 80px;
}

.avatar-4xl .avatar-name {
  font-size: 2.04167rem
}

.avatar-4xl .avatar-emoji {
  font-size: 6.125rem
}


.avatar-4xl .rounded-soft {
  border-radius: 1.02083rem !important
}

.avatar-5xl {
  height: 10.5rem;
  width: 10.5rem
}

.avatar-5xl .avatar-name {
  font-size: 3.5rem
}

.avatar-5xl .avatar-emoji {
  font-size: 10.5rem
}


.avatar-5xl .rounded-soft {
  border-radius: 1.75rem !important
}

.avatar-profile {
  position: absolute;
  bottom: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%)
}

angular2-multiselect.drop-sm .c-btn {
  min-height: 1.9rem !important;
}

angular2-multiselect.drop-medium .c-btn {
  min-height: 35.59px;
}

angular2-multiselect.drop-sm .c-token {
  padding: 0 22px 0 8px !important;
  margin-top: 0 !important;
}

.day {
  margin: 5px !important;
}

.day:hover {
  cursor: pointer;
}

.year-calendar__body__month {
  margin-bottom: 0 !important;
}

.year-calendar__body__month__inner__title {
  color: $lunia-primary;
}

.year-calendar__body {
  padding-top: 0 !important;
}

.year-calendar__body__month__inner__week__day--label {
  font-weight: bold;
  font-style: italic;
}


.year-calendar__header {
  width: 100% !important;
  padding-top: 0 !important;


}

.year-calendar__header__options {
}

.borrar-card {
  position: absolute;
  top: 0;
  right: 0;
}

/*DRAG & DROP*/
.dropZone {
  z-index: 10000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d2d2d2;
  opacity: 0.5;
}

.dias-municipio-container {
  height: 14rem;
}

.background-descanso {
  background-color: #e3d9f4;
}

.text-descanso {
  color: #e3d9f4 !important;
}

.btn-descanso {
  color: black;
  background-color: #e3d9f4;
}

.btn-descanso:hover {
  color: black;
  background-color: #d2c6e7;
}

.nuevo-badge {
  display: inline-block;
  height: 1.2em;
  position: relative;
}

.nuevo-badge-menu {
  display: inline-block;
  height: 1.2em;
  position: relative;
}

.nuevo-badge[data-count]:after {
  position: absolute;
  right: 1%;
  top: 50%;
  content: attr(data-count);
  font-size: 30%;
  padding: .6em;
  border-radius: 999px;
  line-height: .75em;
  color: #fff;
  background: rgba(255, 0, 0, 1);
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}

.nuevo-badge-menu[data-count]:after {
  position: absolute;
  right: -30%;
  top: 70%;
  content: attr(data-count);
  font-size: 42%;
  padding: .6em;
  border-radius: 999px;
  line-height: .75em;
  color: #fff;
  background: rgba(255, 0, 0, 1);
  text-align: center;
  min-width: 2em;
  font-weight: bolder;
}

.nuevo-badge-stack-1x {
  text-align: center;
  width: 100%;
}

.wg-h {
  min-height: 150px !important;
}

.text-line-through {
  text-decoration: line-through;
}

.font-google {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #3c4043;
}

::ng-deep ngx-ui-loader > div.ngx-overlay.ngx-position-absolute.loading-foreground {
  z-index: 999 !important;
}

.header-h {
  min-height: 83px;
}

.sombreado {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.20);
}

.bg-soft-primary {
  background-color: #e3d9f4;
}

.wg-font-size {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.md-font-size {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
