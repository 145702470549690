$font-family-base: 'Roboto';
$font-size-base: 0.9rem;
$danger: #B40B00;

$green-jauja: #b4bd00;
$orange-lunia: #B40B00;

$green-jauja-light: #d5d764;
$red-jauja: #B40B00;
$red-jauja-light: #fc9587;

$lunia-primary: #570dbf;

$theme-colors: (
  primary: $lunia-primary,
  secondary: #B40B00,
  danger: $danger,
  success: #198754,
  warning: #ffc107,
  info: #0dcaf0,
  light: #f8f9fa,
  dark: #212529,
);

$border-radius: 2px;
