/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

$theme: matx.define-theme((
  color: (
    theme-type: light,
    primary: matx.$m3-azure-palette,
    tertiary: matx.$m3-blue-palette,
  )
));

@include mat.core();
@include matx.color-variants-back-compat($theme);

:root {
  @include mat.all-component-themes($theme);
}

@import 'assets/scss/variables.scss';
@import 'bootstrap/scss/bootstrap.scss';
@import 'ngx-toastr/toastr-bs5-alert';
@import "@fortawesome/fontawesome-free/css/all.css";
@import 'assets/scss/theme.scss';
@import 'assets/scss/style.css';
@import "@ng-select/ng-select/themes/default.theme.css";
@import "assets/fonts/fonts.scss";


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.font-archivo {
  font-family: Archivo-Black, sans-serif;
}

.fc-title {
  color: white;
}

.text-left-table-button > span > button {
  text-align: left;
}

map-view {
  height: 400px;
}

.popper,
.custom-tooltip {
  position: absolute;
  z-index: 9999;
  background: #000000;
  color: #ffffff;
  width: 150px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
}

.style5 .custom-tooltip {
  background: #1E252B;
  color: #FFFFFF;
  max-width: 200px;
  width: auto;
  font-size: .8rem;
  padding: .5em 1em;
}

.popper .popper__arrow,
.custom-tooltip .custom-tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.custom-tooltip .custom-tooltip-arrow,
.popper .popper__arrow {
  border-color: #131313;
}

.style5 .custom-tooltip .custom-tooltip-arrow {
  border-color: #1E252B;
}

.popper[x-placement^="top"],
.custom-tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.popper[x-placement^="top"] .popper__arrow,
.custom-tooltip[x-placement^="top"] .custom-tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^="bottom"],
.custom-tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.custom-tooltip[x-placement^="bottom"] .custom-tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.custom-tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
  margin-left: 5px;
}

.popper[x-placement^="right"] .popper__arrow,
.custom-tooltip[x-placement^="right"] .custom-tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.popper[x-placement^="left"],
.custom-tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.popper[x-placement^="left"] .popper__arrow,
.custom-tooltip[x-placement^="left"] .custom-tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s;
}

.fade-in {
  opacity: 1;
  transition: opacity 1s;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.table-logo {
  width: 50px !important;
}

.table-no-logo {
  width: 50px !important;
  height: 30px !important;
}

.boton-oculto {
  display: none;
}

tr:hover .boton-oculto {
  display: inline;
}

.popover {
  z-index: 9001;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.fc-bg-event.border-red {
  border: 1px solid crimson;
}

.rounded {
  border-radius: 15px !important;
}

.rounded-h-card {
  border-radius: 15px 15px 0 0 !important;
}

.btn-siguiente-paso {
  min-width: 230px;
  font-size: 0.8rem;
  font-weight: bold;
}

.btn-siguiente-carousel {
  min-width: 300px;
  font-size: 1rem;
  font-weight: bold;
}

.sombra-sutil {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.20);
}

.contenedor-login {
  max-width: 550px;
}

.input-login {
  border-radius: 13px;
  background: var(--secundario-blanco, #FFF);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.20);
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  padding-left: 16px !important;
  width: 100%;
  border: 0;
}

.input-alf {
  border-radius: 13px;
  background: var(--secundario-blanco, #FFF);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.20);
  padding: 14px;
  width: 100%;
  border: 0;
}

.input-login:focus {
  outline: none;
}

.semibold {
  font-weight: 500;
}

.bg-gris {
  background-color: #f1f1f1;
}

.bg-paginador {
  background-color: #E4E2E9;
}

.bg-blanco {
  background-color: #ffffff;
}

.row-bordered {
  background-color: white;
}

.col-bordered {
  border: 0.4rem solid white;
  background-color: #f1f1f1;
}

.widget-totales-contenedor {
  min-height: 255px;
}

.widget-totales {
  min-height: 208px;
}

.select-container {
  z-index: 0;
  position: relative;
  width: 100%;
  display: inline-block;
}

.chevron {
  position: absolute;
  right: 10px; /* ajusta como necesites */
  top: 50%; /* centra verticalmente el icono */
  transform: translateY(-50%);
  font-size: 20px /* centra verticalmente el icono */
}

.select-categorias {
  display: flex;
  border: 0;
  width: 100%;
  height: 55px;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 5px 5px 0 0;
  background: #F1F1F1;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  appearance: none;
  -webkit-appearance: none;
}

.select-categorias-sm {
  display: flex;
  border: 0;
  width: 100%;
  height: 55px;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 5px 5px 0 0;
  background: #F1F1F1;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  appearance: none;
  -webkit-appearance: none;
}

.dropdown-elementos {
  position: absolute;
  top: 100%;
  z-index: 1;
  left: 0;
  width: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-elementos-sm {
  position: absolute;
  top: 100%;
  z-index: 1;
  left: 0;
  width: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
}

.elemento {
  height: 55px;
  padding: 0 16px;
  align-items: center;
  gap: 16px;
  border: 1px solid var(--secundario-gris-01, #F1F1F1);
  background: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.elemento-sm {
  height: 55px;
  padding: 0 16px;
  align-items: center;
  gap: 16px;
  border: 1px solid var(--secundario-gris-01, #F1F1F1);
  background: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.elemento-sm:hover {
  background: #faf8f8;
}

.elemento:hover {
  background: #faf8f8;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  max-width: 100%; /* Ajusta este valor según sea necesario */
  display: inline-block; /* Asegura que la clase ellipsis-text funcione correctamente */
  user-select: none;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.elemento-abierto {
  display: flex;
}

.elemento-cerrado {
  display: none;
}

.select-categorias:focus {
  outline: none;
}

.select-categorias-sm:focus {
  outline: none;
}

.botones-fichaje {
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
}

.boton-vincular {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
  border-radius: 5px;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #3c4043;
}

w-85 {
  width: 85% !important;
}

.fichar-entrada {
  background: #00B49E;
}

.fichar-entrada:hover {
  background: #008070;
}

.fichar-fin-descanso {
  background: #B40B00;
}

.boton-cancelar {
  border: 1px solid #990900;
  stroke: #990900;
  color: #B40B00;
  background: #FFF;
}

.boton-aceptar {
  background: #E3D9F4;
  color: #000000;
}

.boton-aceptar:hover {
  background: #D1C2E6;
}

.boton-cancelar:hover {
  background: #990900;
  color: white;
}


.fichar-salida:hover, .fichar-fin-descanso:hover {
  background: #990900;
}

.fichar-inicio-descanso {
  background: #570DBF;
}

.fichar-inicio-descanso:hover {
  background: #2D0C6F;
}

.mensaje-confirmacion {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #000;
}

.widget-fichaje {
  min-height: 343px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  border-radius: 13px;
  background: var(--secundario-blanco, #FFF);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
}

.widget-info-usuario {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  border-radius: 13px;
  background: var(--secundario-blanco, #FFF);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
}

.card-widget {
  overflow-y: hidden;
  min-height: 380px;
  border-radius: 13px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20) !important;
}

.card-widget-configuracion {
  overflow-y: hidden;
  border-radius: 13px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20) !important;
}

.card-widget-ausencias {
  min-height: 495px;
  border-radius: 13px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20) !important;
}

.card-widget-nuevos-documentos {
  overflow-y: hidden;
  min-height: 350px;
  border-radius: 13px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20) !important;
}

.cierra-blanco {
  color: white;
}

.preview-header-widget {
  // solo borde arriba deracha e izquierda
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2D0C6F;
  font-size: 18px;
  font-style: normal;
  border-radius: 0;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 84px;
}

.card-header-widget {
  // solo borde arriba deracha e izquierda
  border-radius: 13px 13px 0 0 !important;
  color: white;
  background: #2D0C6F;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}

.card-header-documentos {
  border-radius: 13px 13px 0 0 !important;
  color: white;
  background: #570DBF;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}

.bg-stripped {
  background-color: #F3F0F9;
}

.icon-widget-size {
  font-size: 1.2em;
}

.icon-1-8 {
  font-size: 1.8em;
}

.icon-1-5 {
  font-size: 1.5em;
}

.icon-3 {
  font-size: 3em;
}

.icon-2 {
  font-size: 2rem;
}

.p-icon-w {
  padding-left: 0.7em !important;
}

.font-weight-500 {
  font-weight: 500;
}

.no-underline:hover {
  text-decoration: none;
}

.contenedor-descanso {
  width: 30%;
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.10);
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0 !important;
  background-color: #E3D9F4 !important;
  height: 56px;
}

.boton-modal-primary {
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #570DBF;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
  color: #FFFFFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
}

.boton-modal-primary:disabled {
  background-color: #570DBF;
  color: #FFFFFF;
  opacity: 0.5;
}

.boton-modal-cancelar {
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #990900;
  stroke: #990900;
  color: #B40B00;
  background: #FFF;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.boton-modal-cancelar:hover {
  background: #990900;
  color: white;
}

.boton-primary-docs {
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 47%;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #570DBF;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
  color: #FFFFFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
  margin-left: 1.5%;
  margin-right: 1.5%;
}

.boton-secondary-docs {
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 90%;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #E3D9F4;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
  margin-left: 1%;
  margin-right: 1%;
}

.label-ausencia-bold {
  font-size: 18px;
  font-weight: 600;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin-top: 3px;
}


.label-ausencia {
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.sm-boton-primary {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #570DBF;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.sm-boton-primary:hover {
  color: #FFFFFF;
}

.sm-boton-primary:disabled {
  opacity: 0.5;
}

.sm-boton-secondary {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #F1F1F1;
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.separador-ausencias {
  margin-top: 0;

}

.toggle-buttons-container {
  background-color: #F1F1F1;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.modal-header-primary {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #570DBF;
  color: white;
  height: 58px;
}

.modal-content {
  border-radius: 13px;
}

.modal-header-primary .close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: white;
  margin-bottom: 15px;
}

.modal-header-primary .close:hover {
  color: white;
}

.icon-input-ausencias {
  background-color: #570DBF;
  border-radius: 0 5px 5px 0;
  color: white;
}

.icon-input-ausencias-disabled {
  opacity: 0.65;
}

.input-ausencias {
  border-radius: 5px 0 0 5px;
}

.btn-bg-primary {
  color: #fff;
  background-color: #570DBF;
}

.btn-bg-primary:hover {
  background-color: #742bd8;
}

.btn-bg-secondary {
  color: #000000;
  background-color: #E3D9F4;
}

@media (max-width: 1801px) and (min-width: 1200px) {
  .contenedor-descanso {
    flex-direction: column;
    height: auto;
    border-radius: 5px;
  }

  .icon-descanso2 {
    margin-bottom: 5px;
  }
}

@media (max-width: 1486px) and (min-width: 1200px) {
  .contenedor-descanso {
    width: 40%;
  }
}

@media (max-width: 1437px) and (min-width: 1200px) {
  .contenedor-descanso {
    font-size: 12px;
  }
}

@media (max-width: 1344px) and (min-width: 1200px) {
  .contenedor-descanso {
    font-size: 11px;
  }
}

.alone {
  border-radius: 5px !important;
  max-width: 100% !important;
}

.w-70 {
  width: 70% !important;
}

.widget-tipo-ausencia {
  border-radius: 13px;
  background: #FFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  min-height: 136px;
  overflow: hidden;

  .pendientes {
    background: #F8AE53;
  }

  .rechazadas {
    background: #D9515E;
  }

  .cabecera {
    min-height: 63px;
  }
}

.widget-tipo-ausencia-titulo {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.widget-tipo-ausencia-dias {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.calendar-year {
  color: #570DBF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.today-badge-calendar {
  cursor: pointer;
  width: 40px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #4B9FB5;
  color: white;
  user-select: none;
}

.bg-black {
  background-color: #000000;
}

.arrows-calendar {
  width: 40px;
  height: 30px;
  flex-shrink: 0;
  color: white;
}

arows-calendar:hover {
  background: #3b0783 !important;
}


.left {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  font-size: 18px;
  background: #570DBF !important;

}

::ng-deep ngb-datepicker-month {
  .ngb-dp-week .ngb-dp-weekdays {
    background: none !important;
    border: 0 !important;
  }
}

.bloque-filtros {
  border-radius: 13px;
  background: #FFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
}

.badge-filtros {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  max-height: 44px;
  border-radius: 5px;
  border: 1px solid #9F98AF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  user-select: none;
}

.badge-filtros-activos {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #9F98AF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  user-select: none;
}

.badge-filtros:hover {
  background: rgba(0, 0, 0, 0.04);
}

.boton-cancelar-filtro-activo {
  display: flex;
  align-items: center;
  height: 20px;
  justify-content: center;
  width: 24px !important;
  padding: 12px !important;
  border-radius: 50%;
  background: white;
  border: 0;
}

.boton-cancelar-filtro-activo:hover {
  background: #e0dede;
}

.tabla-ausencias-trabajador {
  border-radius: 0 0 13px 13px;
  background: #FFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  overflow: hidden;

  table > thead {
    background: #E3D9F4;
  }
}

.input-docs {
  border: 1px solid #9F98AF !important;
  border-radius: 5px 0 0 5px;
  height: 40px;
  opacity: 0.7;
  padding-left: 10px;
}

.input-docs::placeholder {
  color: #9F98AF;
  opacity: 0.8;
  font-size: 16px;
}

.input-docs:focus {
  outline: none;
}

.text-20 {
  font-size: 20px;
}

.text-19 {
  font-size: 20px;
}

.text-18 {
  font-size: 18px;
}

.text-16 {
  font-size: 16px;
}

.bg-hover-primary:hover {
  background: #eee4ff !important;
}

.toast-descarga {
  background-image: url("assets/img/circle-down-sharp-light.svg");
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(87, 13, 191, 0.25);
}

.accordion-button {
  text-align: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

.ngb-dp-navigation-chevron {
  color: #570dbf;
}

.form-select-sm {
  height: 30px;
}

.toggle-buttons-modal {
  padding: 3px;
  width: 96%;
  margin-left: 11px;
}

.btn-no-border {
  border: 0;
  border-radius: 5px;
}

.pagination {
  --bs-pagination-active-bg: rgb(87, 13, 191);
  --bs-pagination-active-border-color: rgb(87, 13, 191);
  --bs-pagination-color: rgb(87, 13, 191);
  --bs-pagination-hover-color: rgb(87, 13, 191);
  --bs-pagination-focus-color: rgba(87, 13, 191, 0.25);
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(87, 13, 191, 0.25);
}

.form-control:focus {
  border-color: #570DBF !important;
  box-shadow: 0 0 0 0.25rem rgba(87, 13, 191, 0.25) !important;
}

.form-control-sm:focus {
  border-color: #570DBF !important;
  box-shadow: 0 0 0 0.25rem rgba(87, 13, 191, 0.25) !important;
}

.hover-warning:hover {
  background-color: #e6b524;
}

.bg-primary {
  background-color: #570DBF;
}

.popover-info {
  background: #F2F2F2;
  min-width: 400px !important;
}

.popover-info .popover-arrow::before {
  display: none !important;
}

.popover-info .popover-arrow::after {
  display: none !important;
}
