@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?k1bwe4');
  src: url('../fonts/icomoon.eot?k1bwe4#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?k1bwe4') format('truetype'),
  url('../fonts/icomoon.woff?k1bwe4') format('woff'),
  url('../fonts/icomoon.svg?k1bwe4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plantilla-horarios1:before {
  content: "\e9b7";
}

.icon-info-facturacion1:before {
  content: "\e9b5";
}

.icon-historico-pagos1:before {
  content: "\e9b6";
}

.icon-play-store:before {
  content: "\e977";
}

.icon-app-store:before {
  content: "\e975";
}

.icon-automtico21:before {
  content: "\e973";
}

.icon-origen-automtico1:before {
  content: "\e971";
}

.icon-validada-manualmente11:before {
  content: "\e96f";
}

.icon-validada11:before {
  content: "\e96d";
}

.icon-tipos-de-ausencia11:before {
  content: "\e96b";
}

.icon-subir-documento11:before {
  content: "\e969";
}

.icon-soporte11:before {
  content: "\e979";
}

.icon-sello-entidades11:before {
  content: "\e97b";
}

.icon-sedes11:before {
  content: "\e97d";
}

.icon-poltica-vacaciones211:before {
  content: "\e97f";
}

.icon-poltica-vacaciones11:before {
  content: "\e981";
}

.icon-pendiente-validacion11:before {
  content: "\e983";
}

.icon-pendiente-firma11:before {
  content: "\e985";
}

.icon-papelera11:before {
  content: "\e987";
}

.icon-notificacion11:before {
  content: "\e989";
}

.icon-notas11:before {
  content: "\e98b";
}

.icon-nota-adhesiva11:before {
  content: "\e967";
}

.icon-mis-fichajes11:before {
  content: "\e965";
}

.icon-mis-ausencias-211:before {
  content: "\e963";
}

.icon-mi-cuenta11:before {
  content: "\e961";
}

.icon-Jornadas11:before {
  content: "\e95f";
}

.icon-incorrecto11:before {
  content: "\e95d";
}

.icon-Imformes11:before {
  content: "\e95b";
}

.icon-horariofuerahorario1:before {
  content: "\e959";
}

.icon-home11:before {
  content: "\e98d";
}

.icon-estancias11:before {
  content: "\e98f";
}

.icon-equipos11:before {
  content: "\e991";
}

.icon-equipo11:before {
  content: "\e993";
}

.icon-en-curso11:before {
  content: "\e995";
}

.icon-empleados-vacaciones11:before {
  content: "\e997";
}

.icon-empleados-usuarios11:before {
  content: "\e999";
}

.icon-empleados11:before {
  content: "\e99b";
}

.icon-editar-contrasea11:before {
  content: "\e99d";
}

.icon-documentos11:before {
  content: "\e99f";
}

.icon-documento11:before {
  content: "\e957";
}

.icon-documentacin11:before {
  content: "\e955";
}

.icon-dispositivos11:before {
  content: "\e953";
}

.icon-da-inhbil11:before {
  content: "\e951";
}

.icon-descanso-automtico11:before {
  content: "\e94f";
}

.icon-descanso211:before {
  content: "\e94d";
}

.icon-descanso11:before {
  content: "\e94b";
}

.icon-datos-empresa11:before {
  content: "\e949";
}

.icon-dashboard11:before {
  content: "\e9a1";
}

.icon-Control-laboral-fichajes-y-empleados-estancias11:before {
  content: "\e9a3";
}

.icon-control-laboral-entradas-salidas11:before {
  content: "\e9a5";
}

.icon-control-laboral11:before {
  content: "\e9a7";
}

.icon-configuracin11:before {
  content: "\e9a9";
}

.icon-configuracin-app11:before {
  content: "\e9ab";
}

.icon-cerrar-sesion11:before {
  content: "\e9ad";
}

.icon-cerrado-automticamente11:before {
  content: "\e9af";
}

.icon-categoras11:before {
  content: "\e9b1";
}

.icon-carpeta11:before {
  content: "\e9b3";
}

.icon-camara11:before {
  content: "\e947";
}

.icon-calendarios-festivos11:before {
  content: "\e945";
}

.icon-ausencias-calendario11:before {
  content: "\e943";
}

.icon-ausencias11:before {
  content: "\e941";
}

.icon-anulado11:before {
  content: "\e93f";
}

.icon-agregar-carpeta11:before {
  content: "\e93b";
}

.icon-abrir-estancia11:before {
  content: "\e939";
}

.icon-informacion1:before {
  content: "\e93d";
}

.icon-validada-manualmente:before {
  content: "\e900";
}

.icon-validada:before {
  content: "\e901";
}

.icon-tipos-de-ausencia:before {
  content: "\e902";
}

.icon-subir-documento:before {
  content: "\e903";
}

.icon-soporte:before {
  content: "\e904";
}

.icon-sello-entidades:before {
  content: "\e905";
}

.icon-sedes:before {
  content: "\e906";
}

.icon-poltica-vacaciones2:before {
  content: "\e907";
}

.icon-poltica-vacaciones:before {
  content: "\e908";
}

.icon-pendiente-validacion:before {
  content: "\e909";
}

.icon-pendiente-firma:before {
  content: "\e90a";
}

.icon-papelera:before {
  content: "\e90b";
}

.icon-notificacion:before {
  content: "\e90c";
}

.icon-notas:before {
  content: "\e90d";
}

.icon-nota-adhesiva:before {
  content: "\e90e";
}

.icon-mis-fichajes:before {
  content: "\e90f";
}

.icon-mis-ausencias-2:before {
  content: "\e910";
}

.icon-mi-cuenta:before {
  content: "\e911";
}

.icon-Jornadas:before {
  content: "\e912";
}

.icon-incorrecto:before {
  content: "\e913";
}

.icon-Imformes:before {
  content: "\e914";
}

.icon-horariofuerahorario:before {
  content: "\e915";
}

.icon-home:before {
  content: "\e916";
}

.icon-estancias:before {
  content: "\e917";
}

.icon-equipos:before {
  content: "\e918";
}

.icon-equipo:before {
  content: "\e919";
}

.icon-en-curso:before {
  content: "\e91a";
}

.icon-empleados-vacaciones:before {
  content: "\e91b";
}

.icon-empleados-usuarios:before {
  content: "\e91c";
}

.icon-empleados:before {
  content: "\e91d";
}

.icon-editar-contrasea:before {
  content: "\e91e";
}

.icon-documentos:before {
  content: "\e91f";
}

.icon-documento:before {
  content: "\e920";
}

.icon-documentacin:before {
  content: "\e921";
}

.icon-dispositivos:before {
  content: "\e922";
}

.icon-da-inhbil1:before {
  content: "\e923";
}

.icon-descanso-automtico:before {
  content: "\e924";
}

.icon-descanso2:before {
  content: "\e925";
}

.icon-descanso:before {
  content: "\e926";
}

.icon-datos-empresa1:before {
  content: "\e927";
}

.icon-dashboard1:before {
  content: "\e928";
}

.icon-Control-laboral-fichajes-y-empleados-estancias1:before {
  content: "\e929";
}

.icon-control-laboral-entradas-salidas1:before {
  content: "\e92a";
}

.icon-control-laboral1:before {
  content: "\e92b";
}

.icon-configuracin1:before {
  content: "\e92c";
}

.icon-configuracin-app1:before {
  content: "\e92d";
}

.icon-cerrar-sesion1:before {
  content: "\e92e";
}

.icon-cerrado-automticamente1:before {
  content: "\e92f";
}

.icon-categoras1:before {
  content: "\e930";
}

.icon-carpeta1:before {
  content: "\e931";
}

.icon-camara1:before {
  content: "\e932";
}

.icon-calendarios-festivos1:before {
  content: "\e933";
}

.icon-ausencias-calendario1:before {
  content: "\e934";
}

.icon-ausencias1:before {
  content: "\e935";
}

.icon-anulado1:before {
  content: "\e936";
}

.icon-agregar-carpeta1:before {
  content: "\e937";
}

.icon-abrir-estancia1:before {
  content: "\e938";
}

.icon-play-store1:before {
  content: "\e978";
}

.icon-app-store1:before {
  content: "\e976";
}

.icon-automtico211:before {
  content: "\e974";
}

.icon-origen-automtico11:before {
  content: "\e972";
}

.icon-validada-manualmente2:before {
  content: "\e970";
}

.icon-validada2:before {
  content: "\e96e";
}

.icon-tipos-de-ausencia2:before {
  content: "\e96c";
}

.icon-subir-documento2:before {
  content: "\e96a";
}

.icon-soporte2:before {
  content: "\e97a";
}

.icon-sello-entidades2:before {
  content: "\e97c";
}

.icon-sedes2:before {
  content: "\e97e";
}

.icon-poltica-vacaciones22:before {
  content: "\e980";
}

.icon-poltica-vacaciones3:before {
  content: "\e982";
}

.icon-pendiente-validacion2:before {
  content: "\e984";
}

.icon-pendiente-firma2:before {
  content: "\e986";
}

.icon-papelera2:before {
  content: "\e988";
}

.icon-notificacion2:before {
  content: "\e98a";
}

.icon-notas2:before {
  content: "\e98c";
}

.icon-nota-adhesiva2:before {
  content: "\e968";
}

.icon-mis-fichajes21:before {
  content: "\e966";
}

.icon-mis-ausencias-22:before {
  content: "\e964";
}

.icon-mi-cuenta21:before {
  content: "\e962";
}

.icon-Jornadas2:before {
  content: "\e960";
}

.icon-incorrecto2:before {
  content: "\e95e";
}

.icon-Imformes2:before {
  content: "\e95c";
}

.icon-horariofuerahorario2:before {
  content: "\e95a";
}

.icon-home2:before {
  content: "\e98e";
}

.icon-estancias2:before {
  content: "\e990";
}

.icon-equipos2:before {
  content: "\e992";
}

.icon-equipo2:before {
  content: "\e994";
}

.icon-en-curso2:before {
  content: "\e996";
}

.icon-empleados-vacaciones2:before {
  content: "\e998";
}

.icon-empleados-usuarios2:before {
  content: "\e99a";
}

.icon-empleados2:before {
  content: "\e99c";
}

.icon-editar-contrasea2:before {
  content: "\e99e";
}

.icon-documentos2:before {
  content: "\e9a0";
}

.icon-documento2:before {
  content: "\e958";
}

.icon-documentacin2:before {
  content: "\e956";
}

.icon-dispositivos2:before {
  content: "\e954";
}

.icon-da-inhbil2:before {
  content: "\e952";
}

.icon-descanso-automtico2:before {
  content: "\e950";
}

.icon-descanso22:before {
  content: "\e94e";
}

.icon-descanso3:before {
  content: "\e94c";
}

.icon-datos-empresa2:before {
  content: "\e94a";
}

.icon-dashboard2:before {
  content: "\e9a2";
}

.icon-Control-laboral-fichajes-y-empleados-estancias2:before {
  content: "\e9a4";
}

.icon-control-laboral-entradas-salidas2:before {
  content: "\e9a6";
}

.icon-control-laboral2:before {
  content: "\e9a8";
}

.icon-configuracin2:before {
  content: "\e9aa";
}

.icon-configuracin-app2:before {
  content: "\e9ac";
}

.icon-cerrar-sesion2:before {
  content: "\e9ae";
}

.icon-cerrado-automticamente2:before {
  content: "\e9b0";
}

.icon-categoras2:before {
  content: "\e9b2";
}

.icon-carpeta2:before {
  content: "\e9b4";
}

.icon-camara2:before {
  content: "\e948";
}

.icon-calendarios-festivos2:before {
  content: "\e946";
}

.icon-ausencias-calendario2:before {
  content: "\e944";
}

.icon-ausencias2:before {
  content: "\e942";
}

.icon-anulado2:before {
  content: "\e940";
}

.icon-agregar-carpeta2:before {
  content: "\e93c";
}

.icon-abrir-estancia2:before {
  content: "\e93a";
}

.icon-informacion11:before {
  content: "\e93e";
}

.icon-abrir-estancia:before {
  content: "\e9b8";
}

.icon-agregar-carpeta:before {
  content: "\e9b9";
}

.icon-anulado:before {
  content: "\e9ba";
}

.icon-atras:before {
  content: "\e9bb";
}

.icon-ausencia-incorrecta-rechazada:before {
  content: "\e9bc";
}

.icon-ausencias:before {
  content: "\e9bd";
}

.icon-ausencias-calendario:before {
  content: "\e9be";
}

.icon-ausencias-equipo:before {
  content: "\e9bf";
}

.icon-ausencia-validada:before {
  content: "\e9c0";
}

.icon-automtico2:before {
  content: "\e9c1";
}

.icon-buscador:before {
  content: "\e9c2";
}

.icon-calendario:before {
  content: "\e9c3";
}

.icon-calendario-festivos:before {
  content: "\e9c4";
}

.icon-calendarios-festivos:before {
  content: "\e9c5";
}

.icon-camara:before {
  content: "\e9c6";
}

.icon-carpeta:before {
  content: "\e9c7";
}

.icon-categoras:before {
  content: "\e9c8";
}

.icon-categorias-estancia:before {
  content: "\e9c9";
}

.icon-cerrado-automticamente:before {
  content: "\e9ca";
}

.icon-cerrar-sesion:before {
  content: "\e9cb";
}

.icon-Cerrar-sesion:before {
  content: "\e9cc";
}

.icon-check:before {
  content: "\e9cd";
}

.icon-chevron-down:before {
  content: "\e9ce";
}

.icon-chevron-up:before {
  content: "\e9cf";
}

.icon-Close:before {
  content: "\e9d0";
}

.icon-Comenzar-jornada:before {
  content: "\e9d1";
}

.icon-configuracion:before {
  content: "\e9d2";
}

.icon-configuracin:before {
  content: "\e9d3";
}

.icon-configuracin-app:before {
  content: "\e9d4";
}

.icon-contrasea:before {
  content: "\e9d5";
}

.icon-control-laboral:before {
  content: "\e9d6";
}

.icon-control-laboral-entradas-salidas:before {
  content: "\e9d7";
}

.icon-Control-laboral-fichajes-y-empleados-estancias:before {
  content: "\e9d8";
}

.icon-dashboard:before {
  content: "\e9d9";
}

.icon-Datos:before {
  content: "\e9da";
}

.icon-datos-empresa:before {
  content: "\e9db";
}

.icon-delete-trash:before {
  content: "\e9dc";
}

.icon-descanso1:before {
  content: "\e9dd";
}

.icon-descanso21:before {
  content: "\e9de";
}

.icon-descanso-automtico1:before {
  content: "\e9df";
}

.icon-da-inhbil:before {
  content: "\e9e0";
}

.icon-dispositivos1:before {
  content: "\e9e1";
}

.icon-Dispositivos:before {
  content: "\e9e2";
}

.icon-document:before {
  content: "\e9e3";
}

.icon-documentacin1:before {
  content: "\e9e4";
}

.icon-documento1:before {
  content: "\e9e5";
}

.icon-documentos1:before {
  content: "\e9e6";
}

.icon-editar-contrasea1:before {
  content: "\e9e7";
}

.icon-ellipsis-vertical:before {
  content: "\e9e8";
}

.icon-empleados1:before {
  content: "\e9e9";
}

.icon-empleados-usuarios1:before {
  content: "\e9ea";
}

.icon-empleados-vacaciones1:before {
  content: "\e9eb";
}

.icon-en-curso1:before {
  content: "\e9ec";
}

.icon-entidades-legales:before {
  content: "\e9ed";
}

.icon-entradas-y-salidas:before {
  content: "\e9ee";
}

.icon-equipo1:before {
  content: "\e9ef";
}

.icon-equipos1:before {
  content: "\e9f0";
}

.icon-estancias1:before {
  content: "\e9f1";
}

.icon-Estancias:before {
  content: "\e9f2";
}

.icon-eye-sharp-light-1:before {
  content: "\e9f3";
}

.icon-eye-slash:before {
  content: "\e9f4";
}

.icon-fecha:before {
  content: "\e9f5";
}

.icon-filter:before {
  content: "\e9f6";
}

.icon-fotografias-perfil:before {
  content: "\e9f7";
}

.icon-Fuera-horario:before {
  content: "\e9f8";
}

.icon-historico-pagos:before {
  content: "\e9f9";
}

.icon-histrico-pagos:before {
  content: "\e9fa";
}

.icon-home1:before {
  content: "\e9fb";
}

.icon-horarios:before {
  content: "\e9fc";
}

.icon-Imformes1:before {
  content: "\e9fd";
}

.icon-incorrecto1:before {
  content: "\e9fe";
}

.icon-info-facturacion:before {
  content: "\e9ff";
}

.icon-info-facturacin:before {
  content: "\ea00";
}

.icon-info-licencia:before {
  content: "\ea01";
}

.icon-informacion:before {
  content: "\ea02";
}

.icon-Inicio:before {
  content: "\ea03";
}

.icon-jornada-laboral:before {
  content: "\ea04";
}

.icon-jornadas:before {
  content: "\ea05";
}

.icon-Jornadas1:before {
  content: "\ea06";
}

.icon-listado:before {
  content: "\ea07";
}

.icon-mi-cuenta1:before {
  content: "\ea08";
}

.icon-mi-cuenta2:before {
  content: "\ea09";
}

.icon-mis-ausencias-21:before {
  content: "\ea0a";
}

.icon-mis-fichajes1:before {
  content: "\ea0b";
}

.icon-mis-ausencias:before {
  content: "\ea0c";
}

.icon-mis-fichajes2:before {
  content: "\ea0d";
}

.icon-nota-adhesiva1:before {
  content: "\ea0e";
}

.icon-notas1:before {
  content: "\ea0f";
}

.icon-notificacion1:before {
  content: "\ea10";
}

.icon-notifications:before {
  content: "\ea11";
}

.icon-origen-automtico:before {
  content: "\ea12";
}

.icon-papelera1:before {
  content: "\ea13";
}

.icon-pendiente-firma1:before {
  content: "\ea14";
}

.icon-pendiente-validacion1:before {
  content: "\ea15";
}

.icon-plantilla-horarios:before {
  content: "\ea16";
}

.icon-plus:before {
  content: "\ea17";
}

.icon-poltica-vacaciones1:before {
  content: "\ea18";
}

.icon-poltica-vacaciones21:before {
  content: "\ea19";
}

.icon-refresh:before {
  content: "\ea1a";
}

.icon-reportes:before {
  content: "\ea1b";
}

.icon-resumen:before {
  content: "\ea1c";
}

.icon-saldos:before {
  content: "\ea1d";
}

.icon-save:before {
  content: "\ea1e";
}

.icon-sedes1:before {
  content: "\ea1f";
}

.icon-Sedes:before {
  content: "\ea20";
}

.icon-sello-entidades1:before {
  content: "\ea21";
}

.icon-siguiente:before {
  content: "\ea22";
}

.icon-soporte1:before {
  content: "\ea23";
}

.icon-subir-documento1:before {
  content: "\ea24";
}

.icon-Terminar-jornada:before {
  content: "\ea25";
}

.icon-tipos-de-ausencia1:before {
  content: "\ea26";
}

.icon-tipos-ausencias:before {
  content: "\ea27";
}

.icon-total-mes:before {
  content: "\ea28";
}

.icon-trabajadores:before {
  content: "\ea29";
}

.icon-ubicacion:before {
  content: "\ea2a";
}

.icon-vacaciones:before {
  content: "\ea2b";
}

.icon-validada1:before {
  content: "\ea2c";
}

.icon-validada-manualmente1:before {
  content: "\ea2d";
}

.time-size {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
